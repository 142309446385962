import _axios from "@/plugins/axios"
import emitter from "@/plugins/mitt"
import { getStorage, setStorage } from "@/utils/tools"


export const page = {
    namespaced: true,
	state: {
		isCollapsed:getStorage("isCollapsed") || false, // 菜单是否收起
        currentMenuItem:changeStrToObj(getStorage("currentMenuItem")) || {},//当前点击的菜单
        firstMenuItem:changeStrToObj(getStorage("firstMenuItem")) || {},//当前点击父菜单
        secondMenuItem:changeStrToObj(getStorage("secondMenuItem")) || {},//当前点击次父菜单
        thirdMenuItem:changeStrToObj(getStorage("thirdMenuItem")) || {},//当前点击次父菜单
        // subMenuItem:changeStrToObj(getStorage("subMenuItem")) || {},//当前点击子菜单
        topNavBar:changeStrToObj(getStorage("topNavBar")) || [],
        skin:changeStrToObj(getStorage("skin")) || {},//当前点击皮肤颜色
        dictValList:changeStrToObj(getStorage("dictValList")) || [],//系统字典
        subRoutParam:{},//二级路由跳转参数
	},
	mutations: {
		setCollapsed(state,isCollapsed){
            state.isCollapsed = isCollapsed
			setStorage("isCollapsed",isCollapsed)
        },
        setCurrentMenuItem(state,currentMenuItem){
            state.currentMenuItem = currentMenuItem
			setStorage('currentMenuItem',currentMenuItem)
        },
        setFirstMenuItem(state,firstMenuItem){
            state.firstMenuItem = firstMenuItem
			setStorage('firstMenuItem',firstMenuItem)
        },
        setSecondMenuItem(state,secondMenuItem){
            state.secondMenuItem = secondMenuItem
			setStorage('secondMenuItem',secondMenuItem)
        },
        setThirdMenuItem(state,thirdMenuItem){
            state.thirdMenuItem = thirdMenuItem
			setStorage('thirdMenuItem',thirdMenuItem)
        },
        // setSubMenuItem(state,subMenuItem){
        //     state.subMenuItem = subMenuItem
		// 	setStorage('subMenuItem',subMenuItem)
        // },
        setTopNavBar(state,topNavBar){
            state.topNavBar = topNavBar
			setStorage('topNavBar',topNavBar)
        },
        setSkin(state,skin){
            state.skin = skin
			setStorage('skin',skin)
        },
        setDictValList(state,dictValList){
            state.dictValList = dictValList
			setStorage('dictValList',dictValList)
        },
        setSubRoutParam(state,subRoutParam){
            state.subRoutParam = subRoutParam
        },
        loginOut(state){
            state.isCollapsed = false
            state.currentMenuItem = {}
            state.firstMenuItem = {}
            // state.subMenuItem = {}
            state.topNavBar = []
            state.dictValList = []
            state.subRoutParam = {}
            state.secondMenuItem = {}
            state.setThirdMenuItem = {}
            // state.skin = {}
            setStorage("isCollapsed",false)
            setStorage("currentMenuItem",{})
            setStorage("firstMenuItem",{})
            setStorage("secondMenuItem",{})
            setStorage("setThirdMenuItem",{})
            // setStorage("subMenuItem",{})
            setStorage("topNavBar",[])
			setStorage('dictValList',[])
            // setStorage("skin",{})
            emitter.off('subMenuChange')
            emitter.off('menuClose')
            emitter.off('topBarChange')
            emitter.off('refreshTableList')
            emitter.off('changeSkin')
        },
	},
    actions:{
        //获取字典值
        async getDictVal({ commit }){
            const res = await _axios('post',`/sys/sysDict/page`,{pageNum:1,pageSize:2000},true)
            const {records} = res
            let dataInfo = {};
            records.forEach((item, index) => {
                let { dictType } = item;
                if (!dataInfo[dictType]) {
                    dataInfo[dictType] = {
                        dictType,			
                        children:[]
                    }
                }
                dataInfo[dictType].children.push(item);
            });
            let list = Object.values(dataInfo); // list 转换成功的数据
            commit('setDictValList',list)
        }
    }
}

function changeStrToObj(val){
    let result = {}
    if(val && typeof(val)=='string'){
       return result = JSON.parse(val)
    }
    
}