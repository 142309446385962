import { createRouter, createWebHashHistory } from "vue-router";
import { getToken } from "@/utils/tools.js";
import emitter from "@/plugins/mitt";

const routes = [
  {
    path: "/",
    redirect: "/plat",
  },
  {
    path: "/plat",
    name: "plat",
    meta: {
      title: "收银台",
    },
    component: () => import("@v/plat/index.vue"),
  },
  {
    path: "/index",
    name: "index",
    meta: {
      title: "登录",
    },
    component: () => import("@v/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () => import("@v/login/index.vue"),
  },

  {
    path: "/editShop",
    name: "editShop",
    meta: {
      title: "完善店铺",
    },
    component: () => import("@v/editShop/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

//全局路由守卫
router.beforeEach((to, from, next) => {
  const token = getToken("token") || false;
  // if (to.matched.length === 0) {
  //     next({ path: '/404' })
  //     return
  // }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  //如果没登录,都导向登录页
  next();
});

export default router;
