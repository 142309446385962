import { getStorage } from "@/utils/tools";

export function hasPermission(requiredPermissions) {
  // return true
  const userPermissions = JSON.parse(getStorage("btnPermission"));
console.log(1111,userPermissions)
  return requiredPermissions.some(requiredPermission => {
    return userPermissions.some(userPermission => {
      return userPermission.uri === requiredPermission;
    });
  });
}

const permissionsBtn = {
  install(app) {
    app.config.globalProperties.$hasPermission = hasPermission;
  },
};

export default permissionsBtn;