import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import permissionsBtn from "./common/permissions";
import VueAxios from "vue-axios";
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import 'element-plus/theme-chalk/el-message.css'
// import * as eleIcons from '@element-plus/icons-vue';

export const app = createApp(App);
export const baseUrl =
  import.meta.env.VITE_RES_URL == "/" ? "/mey" : import.meta.env.VITE_RES_URL;
export const uploadpath = baseUrl + "/sys/file-upload/upload";

app.use(VueAxios, axios).use(store).use(router).use(permissionsBtn).mount("#app");
